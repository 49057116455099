.consent_manager-background {
  .consent_manager-headline {
    font-weight: 600 !important;
    font-size: 24px !important;
    font-family: "Raleway" !important;
    padding: 0 0 10px !important;
    text-shadow: 1px 1px 0 #fff !important;
    color: #977351;
  }

  .consent_manager-accept-all {
    background-color: #da6ca3;
    color: #ffffff !important;
    border: 0px !important;
  }
  .consent_manager-accept-all:hover {
    background-color: #e48eb9 !important;
  }

  .consent_manager-save-selection {
    background-color: #977351 !important;
    color: #ffffff !important;
    border: 0px !important;
  }

  .consent_manager-save-selection:hover {
    background-color: #aa896b !important;
  }
  .consent_manager-show-details {
    .icon-info-circled:before {
      background-color: #da6ca3;
    }
  }
}