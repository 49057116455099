/* yellowtail-regular - latin */
@font-face {
  font-family: 'Yellowtail';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/yellowtail-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/yellowtail-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/yellowtail-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/yellowtail-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/yellowtail-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/yellowtail-v18-latin-regular.svg#Yellowtail') format('svg'); /* Legacy iOS */
}


/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/raleway-v28-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/raleway-v28-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v28-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v28-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v28-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v28-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/raleway-v28-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v28-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v28-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/raleway-v28-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/raleway-v28-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway-v28-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway-v28-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/raleway-v28-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway-v28-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}