header {
  @if $header--bg-verlauf-enabled == true {
    @include linear-gradient-3-pos($header--bg-verlauf-color-1,$header--bg-verlauf-color-2,$header--bg-verlauf-color-3,$header--bg-verlauf-color-1-stop,$header--bg-verlauf-color-2-stop,$header--bg-verlauf-color-3-stop);
  } @else {
    background: $header--bg;
  }

  position: fixed;
  width: 100%;
  height: $header--height;
  box-shadow: 0 0 5px #000;
  z-index: 91;

  .container.container-fluid {
    text-align: center;
  }

  .header__top-area {
    position: relative;
    width: $header__top-area--width;
    height: $header__top-area--height;
    margin: $header__top-area--margin;
    a {
      display: block;
    }
    > a {
      margin-bottom: 5px;
    }
  }

  .header__bottom-area {
    background: $header__bottom-area--bg;
    z-index: 80;

    nav > ul {
      background: $header__bottom-area__nav--bg;
    }
  }


  .header__shadow {
    position: fixed;
    bottom: 0px;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
    width: 100vw;
    z-index: 70;
    img {
      vertical-align: top;
      width: 100%;
    }
  }

}

.header__top-area__icons {
  position: absolute;
  top: 62px;
  right: 20px;
  width: auto;
  display: flex;
  justify-content: flex-end;
  div {
    margin-left: 10px;
    i {
      color: $header__top-area__svg--color;
    }
  }
}