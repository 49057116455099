@import "import";
@import "addons/consent_manager";
@import "fonts";


/**
  FARBEN
 */

$pink: #da6ca3;
$pinkHover: rgba(218, 108, 163, 0.85);
$dunkel-braun: #321c0c;
$hell-braunHover: $dunkel-braun;
$hell-braun: #977351;


/**
  GENERAL
 */

$body--font-family: 'Raleway';
$body--font-size: 18px;
$body--font-size-xs: 16px;
$body--font-weight: 300;
$body--font-color: #333;

$h1--color: $pink;
$h1--font-family: 'Yellowtail';
$h1--line-height: 42px;
$h1--text-transform: none;
$h1--font-size: 50px;
$h1--font-weight: 100;
$h1--padding: 0px 0px 5px 0px;
$h1--text-shadow: 1px 1px 0px #fff;


$h2--color: $h1--color;
$h2--font-family: $h1--font-family;
$h2--line-height: $h1--line-height;
$h2--text-transform: $h1--text-transform;
$h2--font-size: $h1--font-size;
$h2--font-size-xs: 30px;
$h2--font-weight: $h1--font-weight;
$h2--padding: $h1--padding;
$h2--text-shadow: $h1--text-shadow;

$h3--color: $hell-braun;
$h3--font-family: 'Raleway';
$h3--font-size: 24px;
$h3--font-size-xs: 17px;
$h3--font-weight: 600;
$h3--padding: 0px 0px 10px 0px;
$h3--text-shadow: 1px 1px 0px #fff;

$font-size-h1: 48px;
$font-shadow-h1: 1px 1px 0 #fff;
$font-color-h1: #bb2731;
$font-weight-h1: 500;

$main--font-size-xs: 14px !important;

$font-size-h2: 18px;
$font-color-h2: #8e1a22;
$font-margin-h2: 0 0 10px 0;
$font-weight-h2: 700;

$font-color-h3: #bdd042;
$font-margin-h3: 4px 0 0 0;


$header-bg-verlauf-enabled: false;
$header--bg: #321c0c;
$header--height: 150px;

$header__top-area__row--bg: #977351;
$header__top-area--height: 40px;
$header__bottom-area__nav--bg: rgba(235, 220, 199, 0.8);
$header__top-area__svg--color: $header__top-area__row--bg;
$header__logo--bg: url(../images/logo_head_bg.png) !important;
$header__logo--width: 150px;
$header__logo--height: 140px;
$header__logo--bg-size: 100% !important;

$header__bottom-area__nav--bg: #da6ca3;

$main--padding: $header--height 0px 0px 0px;


$nav__ul__li__a--font-family: 'Yellowtail';
$nav__ul__li__a--color: #da6ca3 !important;
$nav__ul__li__a--link-hover-color: #321c0c !important;
$nav__ul__li__a--font-size: 30px;
$nav__ul__li__a--font-weight: 200;
$nav__ul__li__a--text-shadow: 0.5px 0.5px 0px #ffffff;


$nav__ul__li__dropdown--bg: rgba(255, 255, 255, 0.75);
$nav__ul__li__dropdown__a--font-family: 'Raleway';
$nav__ul__li__dropdown__a--font-weight: 300;
$nav__ul__li__dropdown__a--font-size: 18px;
$nav__ul__li__dropdown__a--color: #321c0c !important;
$nav__ul__li__dropdown__a--link-hover-color: #977351 !important;
$nav__ul__li__dropdown__a--link-hover-bg: rgba(255,255,255,0.5) !important;
$nav__ul__li__dropdown__a--text-align: center !important;

$nav__ul__active__a--color: #321c0c;

$navbar-default-link-color: #da6ca3;
$navbar-link-hover-color: #321c0c;

$flexslider__control-nav__active--color: #da6ca3;
$flexslider__control-nav__normal--color: #321c0c;
$flexslider__control-nav__hover--color: #d3b395;

$main__a--color: $dunkel-braun;
$main__a__hover--color: $hell-braun;



$footer__h2--color: #fff;
$footer__h2--font-family: 'Yellowtail';
$footer__h2--font-size: $h2--font-size;
$footer__h2--font-weight: $h2--font-weight;
$footer__h2--line-height: $h2--line-height;
$footer__h2--text-rendering: geometricPrecision;
$footer__h2--text-shadow: 0 -1px #a0376c;


$footer__h3--color: #fff;
$footer__h3--font-family: $h3--font-family;
$footer__h3--font-weight: $h3--font-weight;

$footer__h3--font-size: $h3--font-size;
$footer__h3--text-shadow: 0 -1px #a0376c;

$footer__a--color: #fff;
$footer__a__hover--color: #ffbbdd;

$footer--font-family: 'Raleway';
$footer--font-weight: 300;
$footer--font-size: 15px;
$footer--padding: 40px 0px 0px 0px;

$footer--bg: $pink;
$footer__balken-bottom--background: $dunkel-braun;
$footer__balken-bottom--color: $hell-braun;
$footer__balken-bottom--font-family: 'Raleway';
$footer__balken-bottom--text-shadow: 0px -1px 0px #150a02;

$footer__balken-bottom2--padding: 10px;
$footer__balken-bottom2--background: $hell-braun;


$linkboxen--box-shadow: 0px 0px 20px rgba(50, 28, 12, 0.1);
$linkboxen__name--background: rgba(255, 255, 255, 0.7);
$linkboxen__imgdiv--border: 1px solid #fcfbf7;
$linkboxen__a--color: $pink;
$linkboxen__a--font-family: Yellowtail;
$linkboxen__a--font-size: 30px;
$linkboxen__a--line-height: 50px;
$linkboxen__a--font-weight: 300;

$linkboxen__a-hover--font-size: 40px !default;
$linkboxen__a-hover--line-height: 60px !default;


$rexshop__list--background: rgba(255,255,255,0.5);
$rexshop__list--font-family: Yellowtail;
$rexshop__list--color: $pink;
$rexshop__list--font-size: 30px;
$rexshop__list--line-height: 50px;
$rexshop__list--font-weight: 300;
$rexshop__list__article__preis--color: $dunkel-braun;
$rexshop__list__article__preis--font-weight: bold;

/**
 GENERAL IMPORT
 */
@import "variables";
@import "general/functions";
@import "general/basics";
@import "header/navigation";
@import "header/header";
@import "header/logo";
@import "content/content";
@import "general/schatten";
@import "general/footer";
@import "teaser/teaser";
@import "teaser/flexslider";
@import "general/fontawesome/fontawesome";
$fa-font-path: '../fonts/fontawesome';

@import "general/fontawesome/fa-regular";
@import "general/fontawesome/fa-brands";
@import "general/fontawesome/fa-solid";
@import "modules/textblock";
@import "modules/link-box";
@import "modules/rexshop";
@import "modules/lightbox";

@import "../css/test.css";


.header__top-area__row {
  background: $header__top-area__row--bg;
  height: $header__top-area--height;
  width: calc(100%);
}

.header__bottom-area {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%) translateY(0%) !important;

  nav {
    transform: translateY(0%) !important;
    border-radius: 5px;
    @include linear-gradient-2-vert(rgba(235, 220, 199, 0.65), rgba(252, 251, 247, 0.95));
    ul {
      border-radius: 5px;
    }
  }
}

.textblock-devider {
  background: none !important;

  background-size: 100% !important;
  background-repeat: no-repeat !important;
  height: 30px !important;
  width: calc(100% + 30px) !important;
  position: relative;
  margin: 0px !important;
  left: -15px;
  border: 0px !important;
  bottom: -20px !important;

  img {
    width: 100%;
    vertical-align: bottom !important;
    bottom: 0px;
    position: absolute;
  }

}

.textblock-image {
  margin-top: 8px;
}

article {
  padding: 20px;
}

.linkboxen {
  margin-top: 40px;
  .linkbox__name {
    div {
      text-shadow: 0.5px 0.5px 0px #fff;
    }
  }
}

#main__content {
  @include gradient-y(#ebdbc6, #fcfbf7, 0%, 100%);
  padding-top: 1px;
  position: relative;
}

.footer__icons {
  font-size: 60px;
  a {
    flex-grow: 1;
    text-align: right;

    i {
      margin-right: 27px;
    }
  }
}

.balken-bottom {
  a:link {
    text-shadow: none !important;
  }
  a:hover {
    color: $hell-braun;
    text-shadow: none;
  }
}

  body {
    margin-top: -4px;
    font-size: 17px;
    background: #e5e5e5;
    color: #402a1a;
  }

#compo-footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-width: 400px;
  max-height: 335px;
  z-index: 80;
  min-width: 360px;

  .optimizedpicture {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    picture {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .header__top-area__icons {
    div {
      display: none;
    }
    .oeffnungszeiten {
      display: block;
      border: 0px !important;
      .oeffnungszeiten__status {
        display: block;
      }
      .oeffnungszeiten__times {
        display: block;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header {
    height: 100px !important;
    .oeffnungszeiten {
      color: $dunkel-braun;
      border-left: 1px solid $dunkel-braun;
      height: 27px;

      .oeffnungszeiten__times {
        margin-top: 0px;
      }
    }

    .logo {
      height: auto;
      bottom: -28px;
      position: absolute;
      top: unset;
      left: 74px;
      img {
        width: 101px;
      }
    }
    .header__top-area {
      .header__top-area__icons {
        top: 8px !important;
        div {
          a {
            i {
              color: $dunkel-braun;
            }
          }
        }
      }
    }
    .header__bottom-area {
      top: 60px !important;
    }
  }

  #main__content {
    .main__content__ajax {
      .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}


.rexshop__list__article--title {
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: rgba(255,255,255, 0.7);
}

.rexshop__list__article--inhaltsstoffe {
  font-size: 12px;
}

.shoppingcart--items {
  position: absolute;
  top: 16px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  line-height: 5px;
  font-size: 11px;
}

footer {
  text-shadow: 0px -1px #a0376c !important;
  a {
    text-shadow: 0px -1px #a0376c !important;
  }
  @include media-breakpoint-down(md) {
    table {
      text-align: center;
      width: 100%;
    }
  }
}

.main__content__ajax {
  >article,
  >div {
    padding-top: 60px;
  }
  > .container > *,
  > .container-fluid > * {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out 0s, transform 1.0s ease-in-out 0s;
  }
}

.fadeContentIn {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.main__content__ajax .no-fade {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.modal-title {
  font-family: $h3--font-family;
  font-weight: $h3--font-weight;
  font-size: $h3--font-size;
  color: $h3--color;
  line-height: $h3--line-height;
  width: 100%;
  justify-content: center;
  width: 100%;
}

@include media-breakpoint-up(md) {
  .modal-dialog {
    max-width: 560px;
  }
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {

}
.btn-primary {
  background: $pink;
  border: 0px;
}

.table td, .table th {
  border-top: 1px solid rgba(255,255,255, 0.5);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background: $pinkHover !important;
  border: 0px !important;
  box-shadow: none !important;
}

.clearWkorb,
.btn-secondary {
  background: $hell-braun;
  border: 0px;
}

.clearWkorb:hover,
.clearWkorb:active,
.clearWkorb:visited,
.clearWkorb:focus,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus {
  background: $hell-braunHover !important;
  border: 0px !important;
  box-shadow: none !important;
}

/**
 * LAZYLOAD
 */
.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: calc(100% + 10px) !important;
  height: calc(100% + 10px) !important;
  max-width: unset !important;
  max-height: unset !important;
  top: -5px;
  left: -5px;
  transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s, filter 0.3s;
  transition-timing-function: ease-in-out;
  position: absolute
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
  width: 100% !important;
  height: 100% !important;
  top: 0px;
  left: 0px;
}

#addedToCart {

  .modal-body {
    justify-content: center;
    display: flex;
  }
  .modal-footer {
    justify-content: space-between;
  }
}

.rexshop__list__article--preis {
  font-family: $h2--font-family;
  font-weight: $h2--font-weight !important;
  font-size: $h2--font-size;
  color: $h2--color !important;
  line-height: $h2--line-height;
  width: 100%;
  justify-content: center;
  width: 100%;
  span {
    font-size: 12px;
    font-family: $body--font-family;
    color: $body--font-color !important;
  }
}

.rexshop__list__article--versandt {
  font-size: 12px;
  font-family: $body--font-family;
  color: $body--font-color !important;
  position: absolute;
  bottom: 8px;
  left: 18px;
}

.cc-window {
  font-family: $body--font-family !important;
}


.oeffnungszeiten {
  border-left: 1px solid $hell-braun;
  width: 210px;
  color: $hell-braun;
  font-size: 12px;

  .oeffnungszeiten__status {
    font-weight: bold;
    font-size: 16px;
    line-height: 13px;
  }

  .oeffnungszeiten__times {
    margin-top: 5px;
  }
}

.icon-container-right {
  width: 130px;
  font-size: 12px;
  color: $hell-braun;

  .icon-container-divider {
    width: 1px;
    border-left: 1px solid $hell-braun;
    height: 100%;
    position: absolute;
    margin-left: 0px;
  }
  #oeffnungszeiten {
    font-size: 12px;
    margin-left: 10px;
    i {
      font-size: 23px;
      position: absolute;
      top: 0px;
    }
    h6 {
      font-size: 10px;
      color: $hell-braun;
      padding: 4px 0px 0px 25px;
    }
    p {
      margin: 6px 0px 0px 0px;
    }
  }
}

.header__top-area__icons div fa-map-marker-alt {
  font-size: 22px;
}

.lightbox {
  position: fixed;
  top: 10px !important;
  left: 0px !important;
}

strong {
  font-weight: 700;
}

.overlay__message {
  max-width: 85% !important;
  padding: 0px;
  max-height: 400px;
  height: unset !important;

  article {
    padding: 0px;
    margin: 0px;
  }

  .container {
    padding: 0px;
    margin: 0px;
  }

  .container-fluid {
    padding: 0px;
    margin: 0px;
  }
}

.consent_desc_long {
  h2 {
    margin-bottom: 20px;
    color: #977351;
    font-weight: 600;
    font-size: 24px;
    font-family: "Raleway";
    padding: 0 0 10px;
    text-shadow: 1px 1px 0 #fff;
  }
  div {
    margin-bottom: 30px;
  }
  .consent_manager-historytable {
    width: 100%;
  }
  .consent_manager-cookietable {
    th, td {
      padding: 10px 10px 10px 0px;
    }
  }
}

@import "modules/kontakt_icons";